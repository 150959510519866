export const supportedLanguagesCodes = Object.freeze({
  en: 'en',
  el: 'el',
  fr: 'fr',
  pt: 'pt',
  es: 'es',
  de: 'de'
});

export default Object.freeze({
  [supportedLanguagesCodes.en]: 'English',
  [supportedLanguagesCodes.el]: 'Greek',
  [supportedLanguagesCodes.fr]: 'French',
  [supportedLanguagesCodes.pt]: 'Portuguese',
  [supportedLanguagesCodes.es]: 'Spanish',
  [supportedLanguagesCodes.de]: 'German'
});

import siteEditor from './en/siteEditor.json';
import advanced from './pt/advanced.json';
import candidate from './pt/candidate.json';
import eeoc from './pt/eeoc.json';
import gdpr from './pt/gdpr.json';

export default {
  ...candidate,
  advanced,
  eeoc,
  gdpr,
  siteEditor
};

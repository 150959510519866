import supportedLanguages, {supportedLanguagesCodes} from './languages';

// Requires `./messages/*.js` in a webpack-friendly manner and creates and object that
// has one key per locale and the exported .js as its value.
// TODO(anastasios): Makes this loading async and lazily.
const readFolder = context => {
  const folder = {};
  context.keys().forEach(key => (folder[key.split('/')[1].split('.')[0]] = context(key).default));
  return folder;
};

const messages = readFolder(require.context('./messages', true, /\.js$/));

const sharedMessages = readFolder(require.context('../../../../shared/ui/locales', true, /\.js$/));

export {messages, sharedMessages, supportedLanguages, supportedLanguagesCodes};

import advanced from './en/advanced.json';
import candidate from './en/candidate.json';
import eeoc from './en/eeoc.json';
import gdpr from './en/gdpr.json';
import siteEditor from './en/siteEditor.json';

export default {
  ...candidate,
  advanced,
  eeoc,
  gdpr,
  siteEditor
};

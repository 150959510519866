import React from 'react';
import parse from 'html-react-parser';

/**
 * An i18n component that allows html tags inside the translations.
 *
 * Disclaimer: This component uses parse under the hood.
 *
 * Example usage:
 * @example
 *   import _I18n from 'components/i18n';
 *   import i18n from '...your i18n module';
 *
 *   const  I18n = _I18n(i18n);
 *
 *   <I18n Node='div' fruit='banana' className='…' data-ui='…'>
 *     {'fruits.myFavorite'}
 *   </I18n>
 *
 * This would be equivalent to the following translation `i18n.t('fruits.myFavorite', {fruit: 'banana'})`.
 */
export default i18n => ({
  Node = 'span',
  children,
  className,
  style,
  ['data-ui']: dataUi,
  ['data-role']: dataRole,
  ...rest
}) => (
  <Node data-ui={dataUi} data-role={dataRole} className={className} style={style}>
    {parse(i18n.t(React.Children.toArray(children)[0], {...rest}))}
  </Node>
);

import advanced from './el/advanced.json';
import candidate from './el/candidate.json';
import gdpr from './el/gdpr.json';
import eeoc from './en/eeoc.json';
import siteEditor from './en/siteEditor.json';

export default {
  ...candidate,
  advanced,
  eeoc,
  gdpr,
  siteEditor
};

export const supportedLanguagesCodes = Object.freeze({
  de: 'de',
  el: 'el',
  en: 'en',
  es: 'es',
  fr: 'fr',
  pt: 'pt'
});

export default Object.freeze({
  [supportedLanguagesCodes.en]: 'English',
  [supportedLanguagesCodes.el]: 'Greek',
  [supportedLanguagesCodes.fr]: 'French',
  [supportedLanguagesCodes.pt]: 'Portuguese',
  [supportedLanguagesCodes.es]: 'Spanish',
  [supportedLanguagesCodes.de]: 'German'
});

export default Object.freeze({
  de: {
    desktop: 'Deutsch',
    mobile: 'DE'
  },
  el: {
    desktop: 'Ελληνικά',
    mobile: 'ΕΛ'
  },
  en: {
    desktop: 'English',
    mobile: 'EN'
  },
  es: {
    desktop: 'Español',
    mobile: 'ES'
  },
  fr: {
    desktop: 'Français',
    mobile: 'FR'
  },
  pt: {
    desktop: 'Português',
    mobile: 'PT'
  }
});

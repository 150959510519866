import advanced from './de/advanced.json';
import candidate from './de/candidate.json';
import gdpr from './de/gdpr.json';
import eeoc from './en/eeoc.json';
import siteEditor from './en/siteEditor.json';

export default {
  ...candidate,
  advanced,
  eeoc,
  gdpr,
  siteEditor
};

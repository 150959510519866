import {useContext} from 'react';
import I18nContext from './i18n';

export const useI18n = () => {
  const translations = useContext(I18nContext);
  if (typeof translations === 'undefined') {
    throw new Error('useI18n must be used within a I18nContext');
  }
  return translations;
};

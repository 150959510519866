import eeoc from './en/eeoc.json';
import siteEditor from './en/siteEditor.json';
import advanced from './fr/advanced.json';
import candidate from './fr/candidate.json';
import gdpr from './fr/gdpr.json';

export default {
  ...candidate,
  advanced,
  eeoc,
  gdpr,
  siteEditor
};
